import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { Eventos } from "./Eventos";
import { Empresas } from "./Empresas";
import { auth } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

export function Admin() {
  const navigate = useNavigate();
  if (auth() === false) {
    navigate("/");
  }

  return (
    <Box mt={"-0.9%"}>
      <Tabs bg={"white"} p={5} m={"auto"}>
        <TabList>
          <Tab fontWeight={"bold"} fontSize={"xl"}>
            Eventos
          </Tab>
          <Tab fontWeight={"bold"} fontSize={"xl"}>
            Empresas
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Eventos />
          </TabPanel>
          <TabPanel>
            <Empresas />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
