import { createBrowserRouter } from "react-router-dom";
import { Reserva } from "../pages/Reserva";
import { Home } from "../pages/Home";
import { DashBoard } from "../layout/Dashboard";
import { Admin } from "../pages/Admin";
import { Login } from "../pages/Login";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <DashBoard />,
    children: [
      {
        path: "/reserva/:tipo",
        element: <Reserva />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
]);
