import { Box, Spinner } from "@chakra-ui/react";

export function Loading() {
  return (
    <Box minW={'100%'} ml={"200%"} display={'flex'} justifyContent={'center'} alignItems={'center'} h={'45vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  );
}
