import { Box, Select, Text } from "@chakra-ui/react";
import { Fragment } from "react/jsx-runtime";
import { Lista } from "./lista";
import { IoCalendarOutline } from "react-icons/io5";

export function Reserva() {
  
  return (
    <Fragment>
      <Box
        w={{ mc: "100%", xl: "70%" }}
        display={"flex"}
        m={"auto"}
        mb={{ mc: "7%", xl: "0%" }}
        bg={"white"}
        p={3}
        borderTopLeftRadius={7}
        borderBottomRightRadius={7}
        justifyContent={"space-between"}
        alignItems={"center"}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 1px 2px 0px"}
      >
        <Box display={{mc: 'none', xl: 'flex'}} alignItems={'center'} gap={1} color={"#49479D"}>
          <IoCalendarOutline size={25} />
          <Text
            display={{ mc: "none", xl: "flex" }}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Selecione o período
          </Text>
        </Box>
        <Select w={{ mc: "100%", xl: "15%" }} disabled={true}>
          <option>Agosto</option>
          <option>Setembro</option>
          <option>Outubro</option>
          <option>Novembro</option>
        </Select>
      </Box>
      <Lista />
    </Fragment>
  );
}
