import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Loading } from "../../../Componentes/Loading";
import { Empresa } from "../../../utils/interface";
import { useEmpresas } from "../../../hooks/useEmpresas";
import { Fragment, useState } from "react";
import { Item } from "./item";
import { ModalDetalhesEmpresa } from "./ModalDetails";
import { Pagination } from "../../../Componentes/Pagination";

export function Empresas() {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useEmpresas({ page });
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [empresa, setEmpresa] = useState<Empresa>();

  const onOpenModal = (empresa: Empresa) => {
    setEmpresa(empresa);
    onOpen();
  };

  return (
    <Fragment>
      {empresa && (
        <ModalDetalhesEmpresa
          empresa={empresa}
          onClose={onClose}
          isOpen={isOpen}
          key={"details-empresa"}
        />
      )}
      <Box
        display={{ mc: "none", xl: "flex" }}
        flexDir={"column"}
        m={"auto"}
        bg={"white"}
        w={{ mc: "100%", xl: "80%" }}
        marginTop={"1%"}
        borderRadius={3}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 1px 2px 0px"}
        justifyContent={"center"}
      >
        <TableContainer
          sx={{
            borderRadius: "5px",
            border: "1px solid #cccccc",
          }}
        >
          <Table colorScheme="gray" bg={"white"}>
            <Thead>
              <Tr h={30} bg={"white"}>
                <Th textAlign={"center"}>Razão Social:</Th>
                <Th textAlign={"center"}>CNPJ:</Th>
                <Th textAlign={"center"}>Nome Inscrito:</Th>
                <Th textAlign={"center"}>Telefone:</Th>
                <Th textAlign={"center"}>E-mail:</Th>
                <Th textAlign={"center"}>Ação:</Th>
              </Tr>
            </Thead>
            {isLoading ? (
              <Loading />
            ) : (
              <Tbody>
                {data?.empresas.map((empresas: Empresa) => (
                  <Item
                    key={empresas.id}
                    empresa={empresas}
                    handleOpenDetalis={onOpenModal}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        totalCountofRegisters={data?.meta.total}
        currentPage={data?.meta.current_page}
        onPageChange={setPage}
        numberToPage={data?.meta.to}
        lastPage={data?.meta.last_page}
        registerPerPage={data?.meta.per_page}
      />
    </Fragment>
  );
}
