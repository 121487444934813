import { useQuery } from "@tanstack/react-query";
import { api } from "../lib/axios";

interface getCategoriasResponse {
  tipo: string[];
}

async function getTipo(): Promise<getCategoriasResponse> {
  const response = await api
    .get("tipos")
    .then((response) => response.data);

  const tipo =  response

  return {
    tipo,
  }
}

export function useTipo() {
  return useQuery({
    queryKey: ["tipo"],
    refetchOnWindowFocus: false,
    queryFn: () => getTipo(),
  });
}
