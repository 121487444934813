import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Divider,
  Select,
} from "@chakra-ui/react";
import { MdMoreTime } from "react-icons/md";
import { useTipo } from "../../../hooks/useTipo";
import { FilterReserva } from "../../../utils/interface";

interface ModalTipoEventoProps {
  isOpen: boolean;
  onClose: () => void;
  setFilter: (value: FilterReserva) => void;
  filter: string | undefined;
}

export function ModalTipoEvento({ isOpen, onClose, setFilter, filter}: ModalTipoEventoProps) {
  const { data } = useTipo()

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilter: FilterReserva = {
      tipo: event.target.value,
    };
    setFilter(newFilter);
    onClose()
  }

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent>
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          gap={1}
          color={"#003641"}
        >
          <MdMoreTime size={25} />
          Altere a Cateogoria
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel
              mb={"0"}
              fontSize={"small"}
              fontWeight={"400"}
              color={"#003641"}
            >
              Suas Categorias
            </FormLabel>
            <Select
              border={"2px solid #49479D"}
              boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              onChange={handleFilterChange}
              value={filter}
            >
              {data?.tipo.map((e) => (
                <option value={e} key={e}>{e}</option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
