import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Text,
  ModalHeader,
  ModalCloseButton,
  Divider,
  Button,
} from "@chakra-ui/react";
import { Empresa } from "../../../utils/interface";
import { IoBookOutline } from "react-icons/io5";
import { PiWhatsappLogo } from "react-icons/pi";

interface ModalDetalhesEmpresaProps {
  isOpen: boolean;
  onClose: () => void;
  empresa: Empresa;
}

export function ModalDetalhesEmpresa({
  isOpen,
  onClose,
  empresa,
}: ModalDetalhesEmpresaProps) {
  const handleOpenChatWhats = () => {
    const numeroEmpresa = empresa.telefone;
    const numeroFormatado = numeroEmpresa.replace(/\D/g, "");
    const link = `https://api.whatsapp.com/send?phone=${numeroFormatado}`;
    window.open(link, "_blank");
  };
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent>
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          gap={1}
          color={"#003641"}
        >
          <IoBookOutline size={25} />
          Empresa - {empresa.razaoSocial}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody p={5}>
          <Text fontSize={"small"}>Informações da Empressa</Text>
          <Box
            border={"1px solid #ccc"}
            p={2}
            borderRadius={"5"}
            display={"flex"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={4}
          >
            <Box>
              <Text fontSize={"small"}>Razão Social</Text>
              <Text>{empresa.razaoSocial}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Cnpj</Text>
              <Text>{empresa.cnpj}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Nome Inscrito</Text>
              <Text>{empresa.nomeInscrito}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Telefone</Text>
              <Text>{empresa.telefone}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>E-mail</Text>
              <Text>{empresa.email}</Text>
            </Box>
          </Box>
          <Box mt={"5"} display={"flex"} justifyContent={"right"}>
            <Button
              leftIcon={<PiWhatsappLogo size={20} />}
              borderRadius={"3"}
              _hover={{
                background: "#00AE9D",
                boxShadow: "none",
                color: "white",
              }}
              color={"#49479D"}
              size={"sm"}
              fontSize={"sm"}
              border={"2px solid #003641"}
              bg={"white"}
              boxShadow={"rgba(125, 182, 28, 0.9) 3px 3px"}
              onClick={handleOpenChatWhats}
            >
              Conversar Agora!
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
