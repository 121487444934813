import axios from "axios";
import { getToken } from "./auth";

export const api = axios.create({
  baseURL: 'https://apicalendario.sicoobaracoop.com/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(async config => {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
});