import { useMutation } from "@tanstack/react-query";
import { useToast } from '@chakra-ui/react';
import { api } from "../lib/axios";
import { newFormDataAddVaga } from "../utils/schema";
import { queryClient } from "../lib/reactyQuery";

export const useMutationCreateVaga = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: async (data: newFormDataAddVaga) => {
            await api.post(`reservas`, {
                tipo: data.tipo,
                horarios: data.horarios,
                mes: data.mes,
                data: data.data,
                periodo: data.periodo,
                status:  data.status,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['reservas'] });
            toast({ title: 'Criado com sucesso', status: 'success', duration: 5000, isClosable: true, position: 'top-right' });
        }
    });
}