import { useNavigate } from "react-router-dom";
import { Toast, useToast } from "@chakra-ui/react";
import { api } from "../lib/axios";
import { login } from "../lib/auth";
import { useMutation } from "@tanstack/react-query";
import { newFormDataLogin } from "../utils/schema";
import { AxiosError } from "axios";

export function useLogin() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (data: newFormDataLogin) => {
     const response = await api.post(
        "/login",
        {
          email: data.email,
          password: data.password,
        }
      );
      
      login(response.data.token)
      return navigate('/admin');
     
    },
    onError: (error: AxiosError<{ detail: string }>) => {
      const errorTitle =
        error.response?.data.detail === null
          ? "Ocorreu um erro desconhecido"
          : error.response?.data.detail;
      Toast({
        title: errorTitle,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    },
  });
}
