import * as zod from "zod";

export const validationSchemaReservaCreate = zod.object({
    razaoSocial: zod.string().min(2),
    cnpj: zod.string().min(8),
    nomeInscrito: zod.string().min(2),
    telefone: zod.string().min(5),
    email: zod.string().min(4)
})

export const validationSchemaVagaCreate = zod.object({
    tipo: zod.string(),
    horarios: zod.string(),
    mes: zod.string(),
    data: zod.string(),
    periodo: zod.string(),
    status: zod.string(),
})

export const validationSchemaLogin = zod.object({
    email: zod.string().min(4),
    password: zod.string().min(4)
})

export type newFormDataAddReserva = zod.infer<typeof validationSchemaReservaCreate>;
export type newFormDataAddVaga = zod.infer<typeof validationSchemaVagaCreate>;
export type newFormDataLogin = zod.infer<typeof validationSchemaLogin>;

