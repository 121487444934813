import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { theme } from "./style/theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/reactyQuery";

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
