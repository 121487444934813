import {
  Badge,
  Box,
  Button,
  Text,
  Toast,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { CiCloudSun, CiFilter } from "react-icons/ci";
import { Fragment, useState } from "react";
import { FilterReserva, Reservas } from "../../../utils/interface";
import { Pagination } from "../../../Componentes/Pagination";
import { IoIosArrowDown } from "react-icons/io";
import { ModalNewEvent } from "./modalNewEvent";
import { ModalTipoEvento } from "./modalTipoEvent";
import { useReservas } from "../../../hooks/useReservasAll";
import { ModalDetalhesReserva } from "./modalDetalhesReserva";

export function Eventos() {
  const [page, setPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails,
  } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const [filter, setFilter] = useState<FilterReserva>();
  const { data, isLoading } = useReservas({ page, filter });
  const [reserva, setReserva] = useState<undefined | Reservas>(undefined);

  const openModal = () => {
    onOpen();
  };

  const openModalDetails = (reserva: Reservas) => {
    setReserva(reserva);
    onOpenDetails();
  };

  return (
    <Fragment>
      <ModalNewEvent isOpen={isOpen} onClose={onClose} key={"new-event"} />
      {reserva && (
        <ModalDetalhesReserva
          reserva={reserva}
          isOpen={isOpenDetails}
          onClose={onCloseDetails}
          key={"details-reserva"}
        />
      )}
      <ModalTipoEvento
        filter={filter?.tipo}
        setFilter={setFilter}
        isOpen={isOpenFilter}
        onClose={onCloseFilter}
        key={"type-event"}
      />

      <Box p={5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          border={"2px solid #49479D"}
          p={2}
          borderRadius={"5"}
        >
          <Box
            display={"flex"}
            color={"#49479D"}
            alignItems={"center"}
            cursor={"pointer"}
            onClick={onOpenFilter}
          >
            <Text fontSize={"3xl"}>Categoria</Text>
            <IoIosArrowDown size={25} />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <CiFilter size={30} />
            <Button
              onClick={openModal}
              background={"#49479D"}
              color={"white"}
              _hover={{
                border: "2px solid #49479D",
                background: "transparent",
                color: "#49479D",
              }}
            >
              Nova Reserva
            </Button>
          </Box>
        </Box>
        <Box display={"flex"} gap={7} flexWrap={"wrap"}>
          {data?.reserva.map((reserva: Reservas) => (
            <Tooltip textTransform={'capitalize'} borderRadius={'3'} p={3} hasArrow placement={'top'} label={reserva.tipo} bg='#49479D'>
              <Box
                p={2}
                border={"4px solid #49479D"}
                borderRadius={"5px"}
                width={"17%"}
                bg={"white"}
                mt={5}
                boxShadow={"rgba(125, 182, 28, 0.7) 5px 5px"}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Badge
                    colorScheme={
                      reserva.status === "Disponivel" ? "green" : "red"
                    }
                    p={"1"}
                    borderRadius={"4"}
                  >
                    {reserva.mes}
                  </Badge>
                  <Text fontWeight={"bold"} color={"#49479D"}>
                    {reserva.data}
                  </Text>
                </Box>
                <Box mt={5} display={"flex"} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <CiCloudSun size={20} />
                    <Text fontWeight={"400"}>Periodo da {reserva.periodo}</Text>
                  </Box>
                  <Text fontWeight={"bold"} color={"#49479D"}>
                    {reserva.horarios}
                  </Text>
                </Box>
                <Box mt={5} display={"flex"} justifyContent={"space-between"}>
                  <Button
                    size={"small"}
                    fontSize={"small"}
                    p={1}
                    border={"1px solid #49479D"}
                    bg={"transparent"}
                    color={"#49479D"}
                    borderRadius={"2"}
                    _hover={{
                      background: "#49479D",
                      color: "white",
                      border: "2px solid #7DB61C",
                    }}
                    onClick={() => openModalDetails(reserva)}
                  >
                    Detalhes
                  </Button>
                  <Badge
                    colorScheme={
                      reserva.status === "Disponivel" ? "green" : "red"
                    }
                    p={"1"}
                    borderRadius={"4"}
                  >
                    {reserva.status}
                  </Badge>
                </Box>
              </Box>
            </Tooltip>
          ))}
          <Pagination
            totalCountofRegisters={data?.meta.total}
            currentPage={data?.meta.current_page}
            onPageChange={setPage}
            numberToPage={data?.meta.to}
            lastPage={data?.meta.last_page}
            registerPerPage={data?.meta.per_page}
          />
        </Box>
      </Box>
    </Fragment>
  );
}
