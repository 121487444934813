import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { LuCalendarCheck2 } from "react-icons/lu";
import {
  newFormDataAddReserva,
  validationSchemaReservaCreate,
} from "../../utils/schema";
import { useMutationAdd } from "../../hooks/useMutationCreateReserva";
import { Reservas } from "../../utils/interface";
import { useEffect, useState } from "react";
import { useCooperadoFind } from "../../hooks/useCooperadoFind";

interface DrawerAddReserva {
  isOpen: boolean;
  onClose: () => void;
  reserva: Reservas;
}

export function ModalAddReserva({
  isOpen,
  onClose,
  reserva,
}: DrawerAddReserva) {
  const [valorInputSearch, setValorInputSearch] = useState<string>("");
  const { data } = useCooperadoFind({ cnpj: valorInputSearch });
  const mutation = useMutationAdd(reserva.id);
  const [visible, setVisible] = useState<boolean>(false);

  const { register, handleSubmit, reset } = useForm<newFormDataAddReserva>({
    resolver: zodResolver(validationSchemaReservaCreate),
  });

  useEffect(() => {
    const isCooperadoFound = data?.cooperado && Object.keys(data.cooperado).length > 0;
    setVisible(!isCooperadoFound);
  }, [data]);

  const criarReserva = async (data: newFormDataAddReserva) => {
    await mutation.mutateAsync(data);
    onClose();
    reset();
  };

  const handleCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const cleanedValue = inputValue.replace(/\D/g, '');
    setValorInputSearch(cleanedValue)
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent>
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <LuCalendarCheck2 />
          <Text fontSize={{ mc: "small", xl: "lg" }}>
            A reserva é no dia {reserva.data} - Período: {reserva.periodo}
          </Text>
        </ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          <Box display={"flex"} gap={4}>
            <FormControl isRequired>
              <FormLabel fontSize={"small"} mb={0} ml={1}>
                CNPJ:
              </FormLabel>
              <Input
                type={"text"}
                {...register("cnpj")}
                onBlur={handleCnpj}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize={"small"} mb={0} ml={1}>
                Razão Social:
              </FormLabel>
              <Input {...register("razaoSocial")} />
            </FormControl>
          </Box>
          <Box mt={5} display={"flex"} gap={4}>
            <FormControl isRequired>
              <FormLabel fontSize={"small"} mb={0} ml={1}>
                Telefone:
              </FormLabel>
              <Input {...register("telefone")} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize={"small"} mb={0} ml={1}>
                E-mail:
              </FormLabel>
              <Input {...register("email")} type={"e-mail"} />
            </FormControl>
          </Box>
          <FormControl isRequired mt={5}>
            <FormLabel fontSize={"small"} mb={0} ml={1}>
              Informe seu Nome Completo:
            </FormLabel>
            <Input {...register("nomeInscrito")} />
          </FormControl>
          {visible && (
            <Text
              fontWeight={"bold"}
              color={"#ad1616"}
              mt={"1%"}
              fontSize={"sm"}
            >
              Opps! Identificamos que você não é um cooperado Aracoop..{" "}
              <a
                href="https://api.whatsapp.com/send?phone=3432492550"
                style={{ color: "#49479D" }}
              >
                Quero fazer parte
              </a>
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            isDisabled={visible}
            colorScheme="green"
            onClick={handleSubmit(criarReserva)}
            isLoading={mutation.isPending}
            loadingText={"Aguarde.."}
          >
            Reservar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
