import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Divider,
  Box,
  Select,
} from "@chakra-ui/react";
import { MdMoreTime } from "react-icons/md";
import { useMutationCreateVaga } from "../../../hooks/useMutationCrateVaga";
import { useForm } from "react-hook-form";
import {
  newFormDataAddVaga,
  validationSchemaVagaCreate,
} from "../../../utils/schema";
import { zodResolver } from "@hookform/resolvers/zod";

interface ModalAddEvent {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalNewEvent({ isOpen, onClose }: ModalAddEvent) {
  const mutation = useMutationCreateVaga();
  const { register, handleSubmit, reset } = useForm<newFormDataAddVaga>({
    resolver: zodResolver(validationSchemaVagaCreate),
  });

  const createVaga = async (data: newFormDataAddVaga) => {
    await mutation.mutateAsync(data);
    reset();
    onClose();
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent>
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          gap={1}
          color={"#003641"}
        >
          <MdMoreTime size={25} />
          Crie uma Nova Reserva
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pb={6}>
          <Box display={"flex"} gap={5} mb={5}>
            <FormControl>
              <FormLabel
                mb={"0"}
                fontSize={"small"}
                fontWeight={"400"}
                color={"#003641"}
              >
                Categoria da Reserva
              </FormLabel>
              <Input
                {...register("tipo")}
                border={"2px solid #49479D"}
                boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                mb={"0"}
                fontSize={"sm"}
                fontWeight={"400"}
                color={"#003641"}
              >
                Mês
              </FormLabel>
              <Input
                {...register("mes")}
                border={"2px solid #49479D"}
                boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              />{" "}
            </FormControl>
          </Box>
          <Box display={"flex"} gap={5} mb={5}>
            <FormControl>
              <FormLabel
                mb={"0"}
                fontSize={"sm"}
                fontWeight={"400"}
                color={"#003641"}
              >
                Data da Reserva
              </FormLabel>
              <Input
                {...register("data")}
                type={"date"}
                border={"2px solid #49479D"}
                boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              />{" "}
            </FormControl>
            <FormControl>
              <FormLabel
                mb={"0"}
                fontSize={"sm"}
                fontWeight={"400"}
                color={"#003641"}
              >
                Periodo da Reserva
              </FormLabel>
              <Select
                {...register("periodo")}
                border={"2px solid #49479D"}
                boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              >
                <option value={'manhã'} key={'manha'}>Manhã</option>
                <option value={'tarde'} key={'manha'}>Tarde</option>
                <option value={'noite'} key={'manha'}>Noite</option>
              </Select>
            </FormControl>
          </Box>
          <Box display={"flex"} gap={5} mb={5}>
            <FormControl>
              <FormLabel
                mb={"0"}
                fontSize={"sm"}
                fontWeight={"400"}
                color={"#003641"}
              >
                Horário
              </FormLabel>
              <Input
                {...register("horarios")}
                border={"2px solid #49479D"}
                boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              />{" "}
            </FormControl>
            <FormControl>
              <FormLabel
                mb={"0"}
                fontSize={"sm"}
                fontWeight={"400"}
                color={"#003641"}
              >
                Status da Reserva
              </FormLabel>
              <Select
                {...register('status')}
                border={"2px solid #49479D"}
                boxShadow={"rgba(125, 182, 28, 0.7) 2px 3px"}
              >
                <option value={'disponivel'} key={'disponivel'}>Disponivel</option>
                <option value={'reservado'} key={'reservado'}>Reservado</option>
              </Select>
            </FormControl>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button isLoading={mutation.isPending} loadingText={"Aguarde..."} colorScheme="green" onClick={handleSubmit(createVaga)}>
            Ok!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
