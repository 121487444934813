import {
  Badge,
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { ModalAddReserva } from "./modalAddReserva";
import { Pagination } from "../../Componentes/Pagination";
import { useEffect, useState } from "react";
import { useReservas } from "../../hooks/useReservas";
import { FilterReserva, Reservas } from "../../utils/interface";
import { Item } from "./item";
import { BsCalendar2Date } from "react-icons/bs";
import { IoPartlySunnyOutline } from "react-icons/io5";
import { Loading } from "../../Componentes/Loading";
import { useParams } from "react-router-dom";

export function Lista() {
  const { tipo } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reserva, setReserva] = useState<undefined | Reservas>(undefined);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<FilterReserva | undefined>(undefined);
  useEffect(() => {
    setFilter({ tipo });
  }, [tipo])
  const { data, isLoading } = useReservas({ page, filter });

  const openModal = (reservaSelecionada: Reservas) => {
    setReserva(reservaSelecionada);
    onOpen();
  };

  return (
    <>
      {reserva && (
        <ModalAddReserva
          reserva={reserva}
          isOpen={isOpen}
          onClose={onClose}
          key={"modal-add-reserva"}
        />
      )}
      <Box
        display={{ mc: "none", xl: "flex" }}
        flexDir={"column"}
        m={"auto"}
        bg={"white"}
        w={{ mc: "100%", xl: "70%" }}
        marginTop={"1%"}
        borderRadius={3}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 1px 2px 0px"}
        justifyContent={"center"}
      >
        <TableContainer
          sx={{
            borderRadius: "5px",
            border: "1px solid #cccccc",
          }}
        >
          <Table colorScheme="gray" bg={"white"}>
            <Thead>
              <Tr h={30} bg={"white"}>
                <Th textAlign={"center"}>Data:</Th>
                <Th textAlign={"center"}>Período:</Th>
                <Th textAlign={"center"}>Horário:</Th>
                <Th textAlign={"center"}>Situação:</Th>
                <Th textAlign={"center"}>Reserva:</Th>
              </Tr>
            </Thead>
            {isLoading ? (
              <Loading />
            ) : (
              <Tbody>
                {data?.reserva.map((reserva: Reservas) => (
                  <Item
                    key={reserva.id}
                    reserva={reserva}
                    handleOpenDetalis={openModal}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Box>
      {data?.reserva.map((reserva: Reservas) => (
        <Box
          bg={"white"}
          ml={"auto"}
          mr={"auto"}
          mb={7}
          maxW={"99%"}
          borderRadius={"7px"}
          gap={"35px"}
          border={"4px solid #49479D"}
          p={3}
          display={{ mc: "flex", xl: "none" }}
          flexDir={"column"}
          boxShadow={"rgba(125, 182, 28, 0.7) 5px 5px"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            color={"#00AE9D"}
          >
            <Box display={"flex"} gap={1}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                {reserva.data} |
              </Text>
            </Box>
            <Box display={"flex"} gap={1}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                {reserva.horarios} |
              </Text>
            </Box>
            <Box display={"flex"} gap={1}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                Periodo da {reserva.periodo}
              </Text>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            flexDir={"row"}
          >
            <Badge
              display={"flex"}
              p={2}
              borderRadius={"5"}
              alignItems={"center"}
              w={"30%"}
              colorScheme="green"
            >
              {reserva.status}
            </Badge>
            <Button
              _hover={{ background: "white", boxShadow: "none" }}
              marginLeft={{ mc: "15%", xl: "70%" }}
              color={"#49479D"}
              border={"4px solid #003641"}
              bg={"#00AE9D"}
              boxShadow={"rgba(125, 182, 28, 0.9) 5px 5px"}
              onClick={() => openModal(reserva)}
            >
              Reservar
            </Button>
          </Box>
        </Box>
      ))}
      <Pagination
        totalCountofRegisters={data?.meta.total}
        currentPage={data?.meta.current_page}
        onPageChange={setPage}
        numberToPage={data?.meta.to}
        lastPage={data?.meta.last_page}
        registerPerPage={data?.meta.per_page}
      />
    </>
  );
}
