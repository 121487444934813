import { useQuery } from "@tanstack/react-query";
import { api } from "../lib/axios";

interface CooperadoProps {
  cnpj: string;
}

interface getCooperadoResponse{
  cooperado: {id: number};
}

async function getCooperado({
    cnpj,
}: CooperadoProps): Promise<getCooperadoResponse> {
  const response = await api
    .get(`cooperado/${cnpj}`)
    .then((response) => response.data);

  const cooperado = response;

  return {
    cooperado,
  };
}

export function useCooperadoFind({ cnpj }: CooperadoProps) {
  return useQuery({
    queryKey: ["cooperado", { cnpj }],
    refetchOnWindowFocus: false,
    queryFn: () => getCooperado({ cnpj }),
  });
}
