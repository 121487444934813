import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IoMdLogIn } from "react-icons/io";
import { newFormDataLogin, validationSchemaLogin } from "../../utils/schema";
import { useForm } from "react-hook-form";
import { useLogin } from "../../hooks/useMutationLogin";

export function Login() {
  const mutation = useLogin();
  const { register, handleSubmit } = useForm<newFormDataLogin>({
    resolver: zodResolver(validationSchemaLogin),
  });

  const onSubmit = async (data: newFormDataLogin) => {
    await mutation.mutateAsync(data);
  };

  return (
    <Box mt={10}>
      <VStack
        gap={4}
        border={"2px solid #003641"}
        bg={"white"}
        boxShadow={"rgba(125, 182, 28, 0.9) 5px 5px"}
        width={"30%"}
        m={"auto"}
        borderRadius={"5"}
        p={7}
      >
        <Text m={"auto"} fontWeight={"bold"} fontSize={"2xl"}>
          Login
        </Text>
        <Divider />
        <FormControl>
          <FormLabel>Usuário</FormLabel>
          <Input
            {...register("email")}
            size={"lg"}
            borderRadius={"3"}
            border={"2px solid #003641"}
            placeholder="Digite seu usuário"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Senha</FormLabel>
          <Input
            {...register("password")}
            size={"lg"}
            type={'password'}
            borderRadius={"3"}
            border={"2px solid #003641"}
            placeholder="Digite sua senha"
          />
        </FormControl>
        <Button
          ml={"auto"}
          mt={10}
          leftIcon={<IoMdLogIn size={20} />}
          borderRadius={"3"}
          _hover={{
            background: "#00AE9D",
            boxShadow: "none",
            color: "white",
          }}
          color={"#49479D"}
          size={"md"}
          fontSize={"lg"}
          border={"2px solid #003641"}
          bg={"white"}
          boxShadow={"rgba(125, 182, 28, 0.9) 3px 3px"}
            onClick={handleSubmit(onSubmit)}
        >
          Acessar
        </Button>
      </VStack>
    </Box>
  );
}
