import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
  Box,
  Text,
  Badge,
  IconButton,
} from "@chakra-ui/react";
import { IoBookOutline } from "react-icons/io5";
import { Reservas } from "../../../utils/interface";
import { FaRegTrashCan } from "react-icons/fa6";
import { api } from "../../../lib/axios";

interface ModalDetalhesReservaProps {
  isOpen: boolean;
  onClose: () => void;
  reserva: Reservas;
}

export function ModalDetalhesReserva({
  isOpen,
  onClose,
  reserva,
}: ModalDetalhesReservaProps) {
  const deleteReserva = async () => {
    await api.delete(`reserva/${reserva.id}`);
    onClose()
  };
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent>
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          gap={1}
          color={"#003641"}
        >
          <IoBookOutline size={25} />
          Reserva {reserva.data} | {reserva.periodo}
          <Badge colorScheme={"green"}>{reserva.tipo}</Badge>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pb={6}>
          <Text fontSize={"small"}>Informações da Reserva</Text>
          <Box
            border={"1px solid #ccc"}
            p={2}
            borderRadius={"5"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box>
              <Text fontSize={"small"}>Periodo</Text>
              <Text>{reserva.periodo}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Horários</Text>
              <Text>{reserva.horarios}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Mês</Text>
              <Text>{reserva.mes}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Data</Text>
              <Text>{reserva.data}</Text>
            </Box>
            <Box>
              <Text fontSize={"small"}>Status</Text>
              <Badge
                colorScheme={reserva.status === "Disponivel" ? "green" : "red"}
              >
                {reserva.status}
              </Badge>
            </Box>
          </Box>
          {reserva.empresa !== null ? (
            <>
              <Text fontSize={"small"} mt={10}>
                Informações da Empressa
              </Text>
              <Box
                border={"1px solid #ccc"}
                p={2}
                borderRadius={"5"}
                display={"flex"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                gap={4}
              >
                <Box>
                  <Text fontSize={"small"}>Razão Social</Text>
                  <Text>{reserva.empresa?.razaoSocial}</Text>
                </Box>
                <Box>
                  <Text fontSize={"small"}>Cnpj</Text>
                  <Text>{reserva.empresa?.cnpj}</Text>
                </Box>
                <Box>
                  <Text fontSize={"small"}>Nome Inscrito</Text>
                  <Text>{reserva.empresa?.nomeInscrito}</Text>
                </Box>
                <Box>
                  <Text fontSize={"small"}>Telefone</Text>
                  <Text>{reserva.empresa?.telefone}</Text>
                </Box>
                <Box>
                  <Text fontSize={"small"}>E-mail</Text>
                  <Text>{reserva.empresa?.email}</Text>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <IconButton
            onClick={deleteReserva}
            mt={5}
            size={"sm"}
            colorScheme="red"
            aria-label="Excluir"
            icon={<FaRegTrashCan />}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
