import { useQuery } from "@tanstack/react-query";
import { api } from "../lib/axios";
import { Empresa, MetaPagination } from "../utils/interface";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface EmpresasProps {
  page: number;
}

interface getCategoriasResponse {
  empresas: Empresa[];
  meta: MetaPagination;
}

async function getEmpresasAll({
  page,
}: EmpresasProps): Promise<getCategoriasResponse> {
  const response = await api
    .get("empresas", {
      params: {
        page,
      },
    })
    .then((response) => response.data);

  const empresas: Empresa[] = response.data.map((empresas: Empresa) => {
    return {
      ...empresas,
      created_at: format(new Date(empresas.created_at),'dd-MM-yyyy', {locale: ptBR}),
    };
  });

  const meta = {
    current_page: response.current_page,
    last_page: response.last_page,
    per_page: response.per_page,
    to: response.to,
    total: response.total
}

  return {
    empresas,
    meta
  };
}

export function useEmpresas({ page }: EmpresasProps) {
  return useQuery({
    queryKey: ["empresas", { page }],
    refetchOnWindowFocus: false,
    queryFn: () => getEmpresasAll({ page }),
  });
}
