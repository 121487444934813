import { Fragment } from "react/jsx-runtime";
import {
  Box,
  Td,
  Text,
  Tr,
  VStack,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { Empresa } from "../../../utils/interface";
import { CiCircleInfo } from "react-icons/ci";
interface Props {
  empresa: Empresa;
  handleOpenDetalis: (empresa: Empresa) => void;
}

export function Item({ empresa, handleOpenDetalis }: Props) {
  return (
    <Fragment>
      <Tr
        textTransform={"capitalize"}
        h={65}
        key={empresa.id}
        _hover={{ backgroundColor: "#F4F4F4" }}
      >
        <Td>
          <Box display={"flex"} flexDir={"row"}>
            <Text fontWeight={500} m={"auto"}>
              {empresa.razaoSocial}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box display={"flex"}>
            <Text fontWeight={500} m={"auto"}>
              {empresa.cnpj}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box display={"flex"}>
            <Text fontWeight={500} m={"auto"}>
              {empresa.nomeInscrito}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box display={"flex"}>
            <Text fontWeight={500} m={"auto"}>
              {empresa.telefone}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box display={"flex"}>
            <Text fontWeight={500} m={"auto"}>
              {empresa.email}
            </Text>
          </Box>
        </Td>
        <Td>
          <VStack>
            <Box display={"flex"} flexDirection={"row"}>
              <Tooltip
                label={"Detalhes"}
                placement="top"
                bg={"green.500"}
                borderRadius={"base"}
                hasArrow
              >
                <Box cursor={"pointer"} mt={-2}>
                  <IconButton
                    onClick={() => handleOpenDetalis(empresa)}
                    bg={"transparent"}
                    _hover={{ backgroundColor: "transparent" }}
                    aria-label="inactive"
                    icon={<CiCircleInfo  size={24} />}
                  />
                </Box>
              </Tooltip>
            </Box>
          </VStack>
        </Td>
      </Tr>
    </Fragment>
  );
}
